import { Component, OnInit } from "@angular/core";
import { SimpleModalService } from "ngx-simple-modal";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-bullets",
  templateUrl: "./bullets.component.html",
  styleUrls: ["./bullets.component.css"],
})
export class BulletsComponent implements OnInit {
  options = [
    {
      icon: "/assets/img/intelligens-gyartas-1.svg",
      title: "ÜGYFÉL",
      points: [
        " Ez a modul tárolja az ügyfelek adatait, beleértve a kapcsolattartókat, a kapcsolattörténetet. "
      ],
    },
    {
      icon: "/assets/img/intelligens-gyartas-1.svg",
      title: "SZÁMLA",
      points: [
        "Ez a modul nyomon követi a számlákat a kibocsátástól a behajtásig.",
        "Tartalmazhat funkciókat a késedelmes fizetések azonosítására, a behajtási levelek küldésére és a fizetések rögzítésére.",
        "Fizetési feldolgozás: Ez a modul integrálható online fizetési feldolgozókkal, lehetővé téve az ügyfelek számára, hogy online fizessék a számláikat.",
        "Ez javíthatja a behajtási arányokat és felgyorsíthatja a pénzbevételt.",
        "Valamint lehetővé teszi a Banki számlakivonatok automatizált feldolgozást. "
      ],
    },
    {
      icon: "/assets/img/intelligens-gyartas-1.svg",
      title: "A rendszer alaptulajdonságai:",
      points: [
        "Nagy mennyiségű ügyfél és követelés kezelése hatékonyan",
        "Tömeges behajtási folyamatok indítása",
        "Web alapú, böngészőben használható egyszerű, felhasználóbarát kezelőfelület",
        "Központi adatbázist használ, kliens-szerver architektúrára épül",
        "Átlátható behajtási folyamatok, automatizált működés",
        "Magas szintű adat -és jogosultság kezelés",
        "Jól konfigurálható, rugalmas adminisztráció",
        "Interfészen keresztüli élő adatkapcsolat a főkönyvi rendszerrel",
      ],
    },
    {
      icon: "/assets/img/intelligens-gyartas-2.svg",
      title: "Teljesítménymérés és értékelés tényadatok alapján",
      points: [
        "Partnerekkel kapcsolatos levelezés bonyolítása, dokumentum generálás testreszabható sablonok alapján, ezek nyilvántartása az ügyfelekhez és a folyamatokhoz kapcsolódóan",
        "Dokumentum aláírók automatikus kezelése",
        "Partnerkapcsolatok kezelése (adóstársak, örökösök, tulajdonosok, bérlőtársak)",
        "Behajtási folyamatok monitorozása, befizetések, határidők figyelése",
        "Behajtási folyamatok közötti kapcsolatok, összefüggések kezelése",
        "Behajtási költségek kezelése, átadása, szállítói kötelezettség előírása, szállítói számla információk átadása a főkönyvi rendszernek",
        "Kamatszámítás, sztornózás, elengedés",
        "MOKK fizetési meghagyás és végrehajtási eljárás támogatás",
        "Fejlett értesítési rendszer",
        "Tömeges folyamat indítás, nyomtatás, postázás támogatása",
        "Részletes keresési és szűrési lehetőségek",
        "Csoportmunka támogatása",
        "Kifinomult felhasználói jogosultságkezelés",
        "Könnyen integrálható új egyedi behajtási folyamatok",
        ,
      ],
    },
    {
      icon: "/assets/img/intelligens-gyartas-3.svg",
      title: "Tömegesen kezelt folyamatok támogatása",
      points: [
        "Késedelmi kamat számítás",
        "Fizetési emlékeztetők és felszólítások",
        "Ügyvédi felszólítások",
        "Fizetési meghagyások",
        "Szűrések, Nyomtatások",
      ],
    },
  ];

  constructor(private simpleModalService: SimpleModalService) {}

  openModal() {
    this.simpleModalService.addModal(
      ModalComponent,
      { title: "Ingyenes bemutató kérése" },
      {
        closeOnClickOutside: true,
        closeOnEscape: true,
        autoFocus: true,
      }
    );
  }

  ngOnInit() {}
}
