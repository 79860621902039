import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConversionService } from '../../services/conversion.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-kapcsolaturlap',
  templateUrl: './kapcsolaturlap.component.html',
  styleUrls: ['./kapcsolaturlap.component.css']
})

export class KapcsolaturlapComponent {

  @Input() formtitle: string;

  kapcsolatForm = this.fb.group({
    nev: ['', Validators.required],
    cegnev: [''],
    email: ['', Validators.required],
    mobil: [''],
    megjegyzes: [''],
    aszf: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private conversionService: ConversionService,
    private cookieService: CookieService
  ) { }

  submitted = false;
  errors = {
    nev: null,
    aszf: null,
    email: null
  };

  mailpattern(mail: string) {
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)) {
      return true;
    }
    return false;
  }

  validate() {
    if (this.kapcsolatForm.value.nev.length > 0 && this.kapcsolatForm.value.nev.length < 3) {
      this.errors.nev = 'Kérlek valós nevet adj meg!';
    } else if (this.kapcsolatForm.value.nev.length === 0) {
      this.errors.nev = 'Kérlek add meg a neved!';
    } else {
      delete this.errors.nev;
    }
    if (!this.kapcsolatForm.value.aszf) {
      this.errors.aszf = 'Kérlek fogadd el!';
    } else {
      delete this.errors.aszf;
    }
    if (this.kapcsolatForm.value.email.length === 0) {
      this.errors.email = 'Kérlek add meg az e-mail címed!';
    } else if (!this.mailpattern(this.kapcsolatForm.value.email)) {
      this.errors.email = 'Kérlek valós e-mail címet adj meg!';
    } else {
      delete this.errors.email;
    }
  }


  onSubmit() {
    this.validate();
    const parenthis = this;
    if (Object.keys(this.errors).length === 0 && this.errors.constructor === Object) {

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        })
      };

      return this.http.post(environment.host + '/api/ask-us.php', this.kapcsolatForm.value, options) // http://localhost/gyartas/api/ask-us.php
        .subscribe(response => {
          // tslint:disable-next-line
          if (response['message1'] === 'siker') {
            this.submitted = true;
            parenthis.conversionService.conversionFire();
          }
        });
    }
  }


  getCookie(name) {
    return this.cookieService.get(name);
  }

  languagemenu() {
    return this.getCookie("lang") == "hu";
  }
  ngOnInit() {
    window.scrollTo(0, 100);
  }


}
