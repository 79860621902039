import { Component, OnInit } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})

export class NavmenuComponent implements OnInit {
  constructor(private util: AppComponent, private cookieService: CookieService) {}
  languagehu = false;

  useLanguag(language: string): void {
    this.util.useLanguage(language);
    this.languagehu = !this.languagehu;
    this.cookieService.set("lang", language, 365);
  }

  languagemenu() {
    this.languagehu = this.util.getLanguage() == "hu";
  }

  getLanguag(): string {
    return this.util.getLanguage();
  }

  ngOnInit() {
    this.languagemenu();
  }

}
