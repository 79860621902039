import { Component, OnInit, AfterViewInit } from "@angular/core";
import { SimpleModalService } from "ngx-simple-modal";
import { ModalComponent } from "../modal/modal.component";
import { PlbrowserService } from "../../services/plbrowser.service";

@Component({
  selector: "app-bubbles",
  templateUrl: "./bubbles.component.html",
  styleUrls: ["./bubbles.component.css"],
})
export class BubblesComponent implements OnInit, AfterViewInit {
  bubbles = [
    {
      title: "Késedelmi kamat számítás és elengedés",
      image: "assets/img/gyt-1.svg",
      text: "<p>A gyártási folyamatok tevékenységekre és altevékenységekre bonthatók, melyekhez szerszámokat, munkagépeket lehet rendelni. A teljes modul könnyen kezelhető, felhasználóbarát, szemléletes felülettel rendelkezik. A menük, képernyők, szűrési lehetőségek és jelentések testreszabhatóak.</p><p>A PMS gyártástervezés – termelésirányítás segítségével akár a lebonyolultabb gyártási technológiák is átláthatóak, legyen szó futószalagos, munkaállomásos vagy egyéb gyártási tevékenységről, összeszerelő vagy szétszerelő folyamatról. A termelési folyamat során a műveletek megjelenítése, szemléltetése Gantt-Diagrammon történik akár valós idejű adatokkal is kiegészítve.</p>",
    },
    {
      title: "Fizetési emlékeztetők/felszólítások/ügyvédi felszólítások",
      image: "assets/img/gyt-2.svg",
      text: "<p>A gyártástervező algoritmus úgy tervezi meg a tevékenységeket, hogy a kapacitás és erőforrás (gép, szerszám, ember), valamint minden alapanyag rendelkezésre álljon. Paraméterezés segítségével testreszabhatóak az optimalizálás során figyelembe vett tényezők. Az algoritmus természetesen figyelembe veszi a prioritásokat és a határidőket.</p><p>Lehetséges a munkafolyamat hossza szerint optimalizálni, valamint bizonyos gépek kihasználtsága is szempont lehet. A tervezés során minden tevékenység egyénileg paraméterezhető, lehetőség van például előkészítési és utómunkálati időket is megadni.</p>",
    },
    {
      title: "Személyes felkeresések/Részletfizetési megállapodások",
      image: "assets/img/gyt-3.svg",
      text: "<p>A gyártás során keletkező adatok folyamatosan bekerülnek a rendszerbe, melyek folyamatos információval látják el a dolgozókat és gyártásvezetőt is. A termelési tervtől való eltérés azonnal kiértékelhető.</p><p>A munkatársak mobil eszközön követhetik az aktuális feladatlistájukat, valamint rögzíthetik, hogy milyen tevékenységet folytatnak. A gyártás során keletkező információk, gyári számok rögzítése történhet vonalkóddal, QR kóddal, RFID azonosítással stb., így az adatok rögzítése is akár automatizálható. Ennek köszönhetően az egyes munkadarabok aktuális státusza és készültsége is real-time monitorozható.</p>",
    },
    {
      title: "Adósságkezelési támogatások/ Halasztott befizetések",
      image: "assets/img/gyt-4.svg",
      text: "<p>Az anyagszükséglet kezelés és tervezés segítségével optimalizálható a rendelkezésre álló készletmennyiség. Az anyagszükséglet kalkuláció és a raktárkészlet folyamatos korrekt kezelése nagyon fontos minden termelést végző vállalat számára, hiszen ha a gyártás során kiderül, hogy nincsen elegendő alapanyag az súlyos határidő túllépést és pénzügyi veszteséget eredményez a szervezetnek.</p><p>Az sem gazdaságos megoldás, ha a beszerzés arra törekszik, hogy minél kevesebb alkalommal minél nagyobb tételben rendeljen, mert így érhető el a legjobb alapanyagár. Az anyagszükséglet kalkuláció segítségével a készletmennyiség optimalizálható, így mindig a rendelésállomány szintjének megfelelő a raktáron lévő alapanyagkészlet, ami a vállalat pénzügyi eredményességét jelentősen javíthatja.</p>",
    },
    {
      title: "Szerződés felmondások",
      image: "assets/img/gyt-5.svg",
      text: "<p>Kiemelt ügyfelétől új sürgős megrendelés érkezett, változtak a prioritások? Gépmeghibásodás, vagy nem várt betegállományok miatt lecsökkentek a rendelkezésre álló gyártási kapacitások? Ezeknek a változásoknak a gyors és hatékony kezelése kulcsfontosságú az ügyfelek bizalmának megszerzésében és a vállalat pénzügyi eredményességében.</p><p>A PMS gyártástervezés - termelésirányítás szoftver segítségével rugalmasan és gyorsan új gyártási tervet generálhat, amelynek a segítségével folyamatosan reagálni tud a piac változásaira és a nem várt eseményekre.</p>",
    },
    {
      title: "Fizetési meghagyások",
      image: "assets/img/gyt-6.svg",
      text: "<p>A PMS gyártástervezés - termelésirányítás modul a Pannon Set Etalon ERP rendszerbe integrálva megkönnyíti a gyártás teljes folyamata során felmerülő dokumentációt. A teljes munkafolyamat során megszűnnek az óriási Excel táblák és elkallódó papírlapok.</p><p>A bevételezéstől a szállításig, minden folyamat során felmerülő dokumentum könnyedén generálható és akár nyomtatás nélkül mobil eszközön is kezelhető. A gyártás során a gyártási tervektől a gépek karbantartási nyilvántartásáig minden dokumentáció gyorsan kereshetően elérhető, a megfelelő jogosultsággal rendelkező felhasználóknak</p>",
    },
    {
      title: "Végrehajtások/Felszámolási eljárás",
      image: "assets/img/gyt-7.svg",
      text: "<p>A PMS gyártástervezés - termelésirányítás segítségével nem csak a gyártási munkafolyamatokat és időpontokat tudja kontrolálni, hanem a költségeket is. A költségek pontos kalkulációja alapvetően befolyásolja a gyártási tevékenység pénzügyi sikerességét.</p><p>Lehetőség van az ajánlatadás előtt is már egy pénzügyi előkalkulációra, elősegítve a pontosabb kalkulációt az ajánlatokhoz. A termék elkészülése után a tény- és tervadatokból készült kimutatások elemzésére nyílik lehetőség növelve a pénzügyi kontrollt. Ezen funkciók segítségével folyamatosan követni és tervezni lehet a gyártás eredményességét, amely alapvető a vállalkozás sikerességéhez.</p>",
    },
    {
      title: "Kooperációs tevékenységek támogatása",
      image: "assets/img/gyt-8.svg",
      text: "<p>A PMS gyártástervezés - termelésirányítás lehetőséget ad a gyártás egyes szakaszainak a kiszervezésére is, úgy hogy a külső tevékenységek alakulását is nyomon követheti. Partnere egy mobil applikáció segítségével jelezheti, amint végzett egy munkafázissal, ön pedig a többi folyamata között követheti a kiszervezett tevékenységek készültségi fokát is.</p>",
    },
    {
      title: "Vezetői műszerfal",
      image: "assets/img/gyt-9.svg",
      text: "<p>A gyártásvezetők és felsővezetők személyreszabott képernyőn követhetik a számukra legfontosabb információk, mutatószámok (KPI-ok) alakulását. Ezáltal a döntéseket gyorsan és rugalmasan, valós adatok birtokában tudják meghozni. A gyártásvezetők az összes aktuális tevékenység időtervét és az ezekhez tartozó gépeket is figyelhetik egy jól áttekinthető Gantt-diagrammos kezelőfelületen.</p>",
    },
  ];

  constructor(
    private simpleModalService: SimpleModalService,
    private plbrowserService: PlbrowserService
  ) {}

  openModal() {
    this.simpleModalService.addModal(
      ModalComponent,
      { title: "Kapcsolat" },
      {
        closeOnClickOutside: true,
        closeOnEscape: true,
        autoFocus: true,
      }
    );
  }

  onInViewportChange(inViewport: boolean) {
    if (this.plbrowserService.isBrowser) {
      if (inViewport) {
        document
          .querySelector('[fragment="megoldasunk-elonyei"]')
          .classList.add("active");
      } else {
        document
          .querySelector('[fragment="megoldasunk-elonyei"]')
          .classList.remove("active");
      }
    }
  }
  private removeAllElementsClass(elements, className) {
    for (const iterator of elements) {
      iterator.classList.remove(className);
    }
    /* for (let j = 0; j < elements.length; j++) {
      const moreText = elements[j];
      moreText.classList.remove(className);
    } */
  }

  bubbleMethod() {
    const bubbles = document.querySelectorAll(".bubble");
    const moreTexts = document.querySelectorAll(".bubble .more-text");
    for (let index = 0; index < bubbles.length; index++) {
      const bubble = bubbles[index];
      bubble.addEventListener("click", () => {
        let hasOrange = false;
        if (bubble.classList.contains("orange")) {
          hasOrange = true;
        }
        this.removeAllElementsClass(bubbles, "orange");
        if (hasOrange) {
          bubble.classList.remove("orange");
        } else {
          bubble.classList.add("orange");
        }
        const currentMoreText = bubble.querySelector(".more-text");
        let hasOpenClass = false;
        if (currentMoreText.classList.contains("open")) {
          hasOpenClass = true;
        }
        this.removeAllElementsClass(moreTexts, "open");
        if (hasOpenClass) {
          currentMoreText.classList.remove("open");
        } else {
          currentMoreText.classList.add("open");
        }
      });
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.plbrowserService.isBrowser) {
      this.bubbleMethod();
    }
  }
}
