import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { CegunkrolComponent } from './views/cegunkrol/cegunkrol.component';
import { KapcsolatComponent } from './views/kapcsolat/kapcsolat.component';
import { AdatvedelemComponent } from './views/adatvedelem/adatvedelem.component';
import { TamogatasComponent } from './views/tamogatas/tamogatas.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { TovabbiReferenciakComponent } from './components/tovabbireferenciak/tovabbireferenciak.component';
import { FeltoltesAlattComponent } from './views/feltoltesalatt/feltoltesalatt.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'KKR - Követelés Kezelő Rendszer',
      description: 'Gyártó cégek számára fejlesztett személyre szabott szoftverek. Gyártástervezés, termelésirányítás, gyártáskövetés.'
    }
  },
  {
    path: 'cegunkrol',
    component: CegunkrolComponent,
    data: {
      title: 'Cégünkről',
      description: 'Több mint 20 éves fennállásunk kezdete óta felhőalapú vállalatirányítási rendszereket fejlesztünk.'
    }
  },
  {
    path: 'kapcsolat',
    component: KapcsolatComponent,
    data: {
      title: 'Kapcsolat',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'adatvedelem',
    component: AdatvedelemComponent,
    data: {
      title: 'Adatvédelem',
      metarobots: 'noindex,nofollow'
    }
  },
  {
    path: 'tovabbireferenciak',
    component: TovabbiReferenciakComponent,
    data: {
      title: 'TovabbiReferenciakComponent',
      description: 'GINOP 3.2.2 Informatikai Pályázat. Egyedi ügyviteli rendszerét most uniós támogatással készítjük el!'
    }
  },
  {
    path: 'feltoltesalatt',
    component: FeltoltesAlattComponent,
    data: {
      title: 'FeltoltesAlatt',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'tamogatas',
    component: TamogatasComponent,
    data: {
      title: 'Támogatás',
      description: 'GINOP 3.2.2 Informatikai Pályázat. Egyedi ügyviteli rendszerét most uniós támogatással készítjük el!'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: '404',
      metarobots: 'noindex,nofollow'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true,
    // enableTracing: true,
    anchorScrolling: 'enabled',
    // scrollPositionRestoration: 'enabled',
    // onSameUrlNavigation: "reload",
    scrollOffset: [80, 0] // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
