import { Component, OnInit } from '@angular/core';
import { PlbrowserService } from '../../services/plbrowser.service';
import data from '../../../assets/references-vallakozasok.json';

declare const Rellax: any;

@Component({
  selector: 'app-referenciak-vallalkozasok',
  templateUrl: './referenciak-vallalkozasok.component.html',
  styleUrls: ['./referenciak-vallalkozasok.component.css']
})
export class ReferenciakVallalkozasokComponent implements OnInit {

  references = data;

  constructor(private plbrowserService: PlbrowserService) { }

  // onInViewportChange(inViewport: boolean) {
  //   if (this.plbrowserService.isBrowser) {
  //     if (inViewport) {
  //       document.querySelector('[fragment="referenciak"]').classList.add('active');
  //     } else {
  //       document.querySelector('[fragment="referenciak"]').classList.remove('active');
  //     }
  //   }
  // }

  ngOnInit() {
    /*if (this.plbrowserService.isBrowser) {
      const rellax = new Rellax('.rellax2');
    }*/
  }

}
