import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalComponent } from '../modal/modal.component';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-bullets-masik',
  templateUrl: './bulletsmasik.component.html',
  styleUrls: ['./bulletsmasik.component.css']
})
export class BulletsMasikComponent implements OnInit {
  constructor(private simpleModalService: SimpleModalService, private util: AppComponent, private cookieService: CookieService) { }
  languagehu = false;

  options = [
    {
      "icon": "/assets/img/house.png",
      "title": "yellow-dot.menu-one-title",
      "link":"/ingatlan",
      // "description": "yellow-dot.menu-one-description",
      "points": [
        "yellow-dot.menu-one-firstpoint",
        "yellow-dot.menu-one-secondpoint",
        "yellow-dot.menu-one-thirdpoint",
        "yellow-dot.menu-one-fourthpoint",
        "yellow-dot.menu-one-fifthpoint",
        "yellow-dot.menu-one-sixthpoint",
        "yellow-dot.menu-one-seventhpoint"
      ]
    },
    {
      "icon": "/assets/img/wrench.png",
      "title": "yellow-dot.menu-two-title",
      "link":"/muszak",
      "description": "yellow-dot.menu-two-description",
      "points": [
        "yellow-dot.menu-two-firstpoint",
        "yellow-dot.menu-two-secondpoint",
        "yellow-dot.menu-two-thirdpoint",
        "yellow-dot.menu-two-fourthpoint",
      ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-three-title",
      "link":"/szamla",
      "description": "yellow-dot.menu-three-description",
      "points": [
        "yellow-dot.menu-three-firstpoint",
        "yellow-dot.menu-three-secondpoint",
        "yellow-dot.menu-three-thirdpoint",
        "yellow-dot.menu-three-fourthpoint",
        "yellow-dot.menu-three-fifthpoint"
      ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-four-title",
      "description": "yellow-dot.menu-four-description",
      "points": [
        ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-five-title",
      "description": "yellow-dot.menu-five-description",
      "points": [
        "yellow-dot.menu-five-firstpoint",
        "yellow-dot.menu-five-secondpoint",]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-six-title",
      "description": "yellow-dot.menu-six-description",
      "points": [
        "yellow-dot.menu-six-firstpoint",
        "yellow-dot.menu-six-secondpoint",
      "yellow-dot.menu-six-thirdpoint",
    ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-seven-title",
      "description": "yellow-dot.menu-seven-description",
      "points": [
        ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-eight-title",
      "description": "yellow-dot.menu-eight-description",
      "points": [
        ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-nine-title",
      "description": "yellow-dot.menu-nine-description",
      "points": [
        ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-ten-title",
      "description": "yellow-dot.menu-ten-description",
      "points": [
       ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-eleven-title",
      "description": "yellow-dot.menu-eleven-description",
      "points": [
       ]
    }
        
  ];

  getCookie(name) {
    return this.cookieService.get(name);
  }

  openModal() {
    this.simpleModalService.addModal(
      ModalComponent,
      {title: 'Ingyenes bemutató kérése'},
      {
        closeOnClickOutside: true,
        closeOnEscape: true,
        autoFocus: true
      }
    );
  }

  languagemenu() {
    return this.getCookie("lang") == "hu";
  }
  

  ngOnInit() {
  }
}
