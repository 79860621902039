import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    let now = new Date();

    let datetime = now.toLocaleString("en-us", { year: "numeric" });

    // Insert date and time into HTML
    document.getElementById("datetime").innerHTML = datetime;

  // valami

    // let mybutton = document.getElementById("myBtn");

  //   mybutton.addEventListener("click", () => {

  //     window.scrollTo({top: 0, behavior: 'smooth'});
  //     //document.body.scrollTop = 0; // For Safari
  //     //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  // });

  }
}
