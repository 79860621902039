import { Component, OnInit } from "@angular/core";
import { PlbrowserService } from "../../services/plbrowser.service";
import data from "../../../assets/references-tovabbi.json";

declare const Rellax: any;

@Component({
  selector: "app-tovabbireferenciak",
  templateUrl: "./tovabbireferenciak.component.html",
  styleUrls: ["./tovabbireferenciak.component.css"],
})
export class TovabbiReferenciakComponent implements OnInit {
  references = data;

  constructor(private plbrowserService: PlbrowserService) {}

  onInViewportChange(inViewport: boolean) {
    if (this.plbrowserService.isBrowser) {
      if (inViewport) {
        document
          // .querySelector('[fragment="referenciak"]')
          // .classList.add("active");
      } else {
        document
          // .querySelector('[fragment="referenciak"]')
          // .classList.remove("active");
      }
    }
  }

  scr() {
    window.scrollTo(0, 400);
  }
  ngOnInit() {
    /*if (this.plbrowserService.isBrowser) {
      const rellax = new Rellax('.rellax2');
    }*/
  }
  
  ngAfterViewInit() {
    this.scr();
  }
}
