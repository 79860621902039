import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { SeoService } from './services/seo.service';
import { ConversionService } from './services/conversion.service';
import { PlbrowserService } from './services/plbrowser.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    SeoService,
    ConversionService
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private seoService: SeoService,
    private router: Router,
    private plbrowserService: PlbrowserService,
    private conversionService: ConversionService,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {

    if (this.getCookie("lang") == "en") {
      translate.setDefaultLang("en");
      translate.use("en");
      this.cookieService.set("lang", "en", 365);
    } else {
      translate.setDefaultLang("hu");
      translate.use("hu");
      this.cookieService.set("lang", "hu", 365);
    }
  }

  getCookie(name) {
    return this.cookieService.get(name);
  }
  title = "ps-kkr";
  schema = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Pannon Set Kft.',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Fehérvári u. 24. IV./1',
      addressLocality: 'Budapest',
      postalCode: '1117',
      addressCountry: 'HU'
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 47.473935,
      longitude: 19.046466
    },
    url: 'https://www.gyartas.ps.hu',
    email: 'ps@ps.hu',
    logo: 'https://www.gyartas.ps.hu/pannon-set-logo-micro.jpg',
    image: [
      'https://www.gyartas.ps.hu/og-image.png'
    ],
    telephone: '+36 1/789-0575'
  };

  gtagPageView() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // csak prod módban
        if (environment.production) {
          (window as any).dataLayer.push({
            event: 'pageviewcustomevent',
            pagePath: event.urlAfterRedirects,
            pageTitle: this.seoService.currentTitle
          });
          if (event.urlAfterRedirects === '/kapcsolat' || this.seoService.currentTitle === 'Kapcsolat') {         
            gtag('config', 'AW-694561795');
          }
        }
      }
    });
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }

  getLanguage() {
    return this.translate.getDefaultLang();
  }

  ngOnInit(): void {
    this.seoService.init();
    if (this.plbrowserService.isBrowser) {
      this.conversionService.init();
      this.gtagPageView();
    }
  }
}
