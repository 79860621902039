import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Custom extras
import { SwiperModule } from 'ngx-swiper-wrapper';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AgmCoreModule } from '@agm/core';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgxJsonLdModule } from 'ngx-json-ld';

// Components
import { HomeComponent } from './views/home/home.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { KapcsolatComponent } from './views/kapcsolat/kapcsolat.component';
import { NavmenuComponent } from './components/navmenu/navmenu.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CegunkrolComponent } from './views/cegunkrol/cegunkrol.component';
import { SliderComponent } from './components/slider/slider.component';
import { BubblesComponent } from './components/bubbles/bubbles.component';
import { BulletsComponent } from './components/bullets/bullets.component';
import { ScrolltopComponent } from './components/scrolltop/scrolltop.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { ReferenciakKovetelesComponent } from './components/referenciak-koveteles/referenciak-koveteles.component';
import { KapcsolaturlapComponent } from './components/kapcsolaturlap/kapcsolaturlap.component';
import { MapsComponent } from './components/maps/maps.component';
import { ModalComponent } from './components/modal/modal.component';
import { AdatvedelemComponent } from './views/adatvedelem/adatvedelem.component';
import { BevezetoComponent } from './components/bevezeto/bevezeto.component';
import { TamogatasComponent } from './views/tamogatas/tamogatas.component';
import { TamogatasurlapComponent } from './components/tamogatasurlap/tamogatasurlap.component';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from 'ngx-cookie-service';
import { ModulesComponent } from './components/modules/modules.component';
import { BulletsMasikComponent } from './components/bulletsmasik/bulletsmasik.component';
import { AfterBulletsComponent } from './components/afterbullets/afterbullets.component';
import { TovabbiReferenciakComponent } from './components/tovabbireferenciak/tovabbireferenciak.component';
import { FeltoltesAlattComponent } from './views/feltoltesalatt/feltoltesalatt.component';
import { ReferenciakKozmuvekComponent } from './components/referenciak-kozmuvek/referenciak-kozmuvek.component';
import { ReferenciakVallalkozasokComponent} from './components/referenciak-vallalkozasok/referenciak-vallalkozasok.component';
import { ReferenciakComponent } from './components/referenciak/referenciak.component';
import { ReferenciakVallalkozasokTovabbiComponent } from './components/referenciak-vallalkozasok-tovabbi/referenciak-vallalkozasok-tovabbi.component';
import { ReferenciakKozmuvekLongComponent } from './components/referenciak-kozmuvek-long/referenciak-kozmuvek-long.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    KapcsolatComponent,
    NavmenuComponent,
    HeaderComponent,
    FooterComponent,
    CegunkrolComponent,
    SliderComponent,
    BubblesComponent,
    BulletsComponent,
    ScrolltopComponent,
    TestimonialComponent,
    ReferenciakKovetelesComponent,
    KapcsolaturlapComponent,
    MapsComponent,
    ModalComponent,
    AdatvedelemComponent,
    BevezetoComponent,
    TamogatasComponent,
    TamogatasurlapComponent,
    ModulesComponent,
    BulletsMasikComponent,
    AfterBulletsComponent,
    TovabbiReferenciakComponent,
    FeltoltesAlattComponent,
    ReferenciakComponent,
    ReferenciakKozmuvekComponent,
    ReferenciakVallalkozasokTovabbiComponent,
    ReferenciakVallalkozasokComponent,
    ReferenciakKozmuvekLongComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SwiperModule,
    InViewportModule,
    NgxPageScrollModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({ apiKey: environment.gmapApiURL }),
    SimpleModalModule,
    NgxJsonLdModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule
  ],
  providers: [CookieService],
  entryComponents: [
    ModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
